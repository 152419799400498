<template>
    <v-card>
        <Wyswig/>
    </v-card>
</template>
<script>
  const Wyswig = () => import (/* webpackChunkName: "AppBar"*/ '@/components/wyswig/index.vue')

  export default {
    components: {
      Wyswig
    },

  }

</script>